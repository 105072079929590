import React from 'react';

// Styles
import './documents.scss';

export const Documents = () => {
	return (
		<div className="documents-container">
			<h2 className="documents-title">
				<span>CV & Certificates</span> UK
			</h2>
			<section className="documents-line-1">
				<a
					href="assets/pdf/VMOG_CV_2023.pdf"
					className="document-link-resume btn__docs"
					target="_blank"
					rel="noreferrer"
				>
					RESUME
				</a>
				<a
					href="assets/pdf/TechEducators.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					MERN Full Stack Developer
				</a>
				<a
					href="assets/images/certificates/WebDesignMarketing2023.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Web Design & Marketing L3
				</a>
				<a
					href="assets/pdf/2021_React.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					React from 0 to expert
				</a>
				<a
					href="assets/pdf/2020_Web_Development.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Web Development Bootcamp
				</a>
				<a
					href="assets/pdf/CivilEngineer1987.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					CIVIL ENGINEER
				</a>
			</section>
			<h2 className="documents-subtitle">English & Math</h2>
			<section className="documents-line-2">
				<a
					href="assets/pdf/English-L1.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					English Level 1
				</a>
				<a
					href="assets/pdf/Math-Level-2.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Math Level 2
				</a>
			</section>
			<h2 className="documents-subtitle">Other UK certificates</h2>
			<section className="documents-line-3">
				<a
					href="assets/pdf/general/CustomerService.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Customer Service L1
				</a>
				<a
					href="assets/pdf/general/SIA.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					SIA L2
				</a>
				<a
					href="assets/pdf/2024-VICTOR-OTTATI-traffic-marshal.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Traffic MARSHAL
				</a>
				<a
					href="assets/pdf/general/Marshal-VMOG.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Motor sport MARSHAL
				</a>
				<a
					href="assets/pdf/general/ACT.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					ACT
				</a>
				<a
					href="assets/pdf/general/Disability.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Disability Awareness
				</a>
				<a
					href="assets/pdf/general/Diversity.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Diversity, Inclusion and Equity
				</a>
				<a
					href="assets/pdf/general/EmployabilityE3.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Employability Skills L1
				</a>
				<a
					href="assets/pdf/general/EmployabilityE2.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Employability Skills 2
				</a>
				<a
					href="assets/pdf/general/FacilitiesIndustry.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Facilities Industry L1
				</a>
				<a
					href="assets/pdf/general/Working.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Living & Working in the UK
				</a>
				<a
					href="assets/pdf/general/FirstAid.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					First Aid Awareness
				</a>
				<a
					href="assets/pdf/general/Emergency_FirstAidatWork.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Emergency First Aid at Work L3
				</a>
				<a
					href="assets/pdf/general/Health_and_SafetyL1.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Health & Safety L1
				</a>
				<a
					href="assets/pdf/general/ProgressionE3.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Progression
				</a>
				<a
					href="assets/pdf/general/StadiumL1.pdf"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Stadium Intervention L1
				</a>
			</section>
			<h2 className="documents-subtitle">Photography</h2>
			<section className="documents-line-3">
				<a
					href="assets/images/certificates/Digital1.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Digital 1
				</a>
				<a
					href="assets/images/certificates/Digital2.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Digital 2
				</a>
				<a
					href="assets/images/certificates/Digital3.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Digital 3
				</a>
				<a
					href="assets/images/certificates/Color.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Color
				</a>
				<a
					href="assets/images/certificates/Lighting2.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Lighting
				</a>
				<a
					href="assets/images/certificates/Lighting.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Lighting 2
				</a>
				<a
					href="assets/images/certificates/AdPhotography.jpg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Advertising Photography
				</a>
				<a
					href="assets/images/certificates/AdPhotography2.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Advertising Photography 2
				</a>
				<a
					href="assets/images/certificates/DocPhotography.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Documentary Photography
				</a>
				<a
					href="assets/images/certificates/WeddingPhotography.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Wedding photography
				</a>
				<a
					href="assets/images/certificates/Lightroom.jpeg"
					className="document-link btn__docs__ol"
					target="_blank"
					rel="noreferrer"
				>
					Adobe Lightroom
				</a>
			</section>
		</div>
	);
};
